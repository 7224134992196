import { AiOutlineInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillGithub } from "react-icons/ai";

export const links = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About" },
  { id: 3, link: "#services", title: "Services" },
  { id: 4, link: "#portfolio", title: "Portfolio" },
  { id: 5, link: "#contact", title: "Contact" },
];

export const socials = [
  {
    id: 1,
    link: "https://www.instagram.com/raphaelsaab?igsh=MWtvNXE4eHByMG96OA%3D%3D&utm_source=qr",
    icon: <AiOutlineInstagram />,
  },
  { id: 2, link: "https://twitter.com/saab_raphael", icon: <FaXTwitter /> },
  { id: 4, link: "https://github.com/raphaelsaab16", icon: <AiFillGithub /> },
];
