import { RiReactjsLine } from "react-icons/ri";
import { MdLocalGroceryStore } from "react-icons/md";

const data = [
  {
    id: 1,
    icon: <RiReactjsLine />,
    title: "Portfolio Website Development",
    desc: "Boost your brand with tailored portfolios for businesses, NGOs, freelancers, and individuals. Showcase achievements effectively, impressing clients with compelling visuals and storytelling. Stand out in your industry with a polished portfolio.",
  },
  {
    id: 2,
    icon: <MdLocalGroceryStore />,
    title: "E-Commerce Website Development",
    desc: "Transform your business into a thriving online venture with our expert e-commerce solutions. From intuitive design to secure payment systems, we ensure a seamless experience for your customers, boosting sales and brand visibility.",
  },
];

export default data;
