import React from 'react'
import HeaderImage from '../../assets/header.jpg'
import './header.css'
import data from './data'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []);
    return (
        <header id="header">
            <div className="container header__container" data-aos="zoom-out-up" >
                <div className='header__profile' >
                  <img src={HeaderImage} alt="portrait" />
                </div>
                <h3 >Raphael Saab</h3>
                <p>
                    I'm a Web Developer and Designer based in Lebanon. I have a passion for web design and love to create for web and mobile devices.
                </p>
                <div className='header__cta' data-aos="zoom-out-up" data-aos-duration="100000">
                  <a href="#contact" className='btn primary'>Let's Talk!</a>
                  <a href="#portfolio" className='btn light'>My Work</a>
                </div>
                <div className='header__socials'>
                  {
                    data.map(item => <a key={item.id} href={item.link} target='_blank'
                    rel='noopener noreferrer'>{item.icon}</a>)
                  }
                </div> 
                 
            </div>
        </header>
    )

}
export default Header