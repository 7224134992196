import React from 'react'
import './about.css'
import AboutImage from '../../assets/about.jpg'
import { FaDownload } from "react-icons/fa6";
import CV from '../../assets/cv.pdf'
import Card from '../../components/Card';
import data from './data';

const About = () => {
    return(
        
        <section id="about">
            <div className="container about__container">
                <div className = "about__left">
                  <div className="about__portrait">
                    <img src={AboutImage} alt="about" />
                  </div>
                </div>  
                <div className = "about__right">  
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon' >{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                With a comprehensive skill set encompassing C#, C++, PHP 8, JavaScript, HTML 5, CSS3, Python 3, TypeScript, Node.js, Express.js, Bootstrap 5, Visual Studio Code, Spring Boot, Angular, and React, I bring a versatile toolkit to the table as a full stack web developer. My proficiency in front-end technologies such as HTML, CSS, JavaScript, and libraries like Bootstrap 5, Angular, and React allows me to craft engaging and intuitive user interfaces. Meanwhile, my expertise in back-end development with languages like C#, PHP 8, Python 3, Node.js, and frameworks like Spring Boot and Express.js ensures robust and efficient server-side functionality. Whether it's building responsive layouts, implementing dynamic features, or optimizing database performance using SQL, I thrive on the intricacies of web development and am committed to delivering high-quality solutions that exceed expectations.
                </p>
                <p>
                Beyond my technical capabilities, I am a dedicated and collaborative team member who values continuous learning and growth. Currently in my last semester pursuing a degree in Computer Science - Information Technology from Notre Dame University Louaize, I have cultivated a strong foundation in problem-solving and critical thinking. I approach challenges with enthusiasm and resilience, always seeking innovative approaches to overcome obstacles. As a creative thinker and proactive contributor, I am driven by a passion for creating beautiful and functional web applications that enhance user experiences and make a meaningful impact in the digital landscape.
                </p>
                <a href={CV} download className="btn primary"> Download CV<FaDownload /></a>
                </div>
            </div> 
        </section>
    )
}

export default About