const data = [
  {
    id: 1,
    question: "What do you need to start working on my project?",
    answer:
      "It mostly depends on the type of project. But in general, you need a fair idea of what you want, then we can take it from there.",
  },
  {
    id: 2,
    question: "How long will my project take to complete?",
    answer:
      "This depends on the complexity of the project, your availability, and your payment. Once you have this sorted out, I will give you a completion date.",
  },
  {
    id: 5,
    question: "Is hosting and domain registration inclusive in your pricing?",
    answer:
      "No! Hosting and domain is taken care of separately. You can pay for it or take care of it on your own if you know how to.",
  },
  {
    id: 4,
    question: "How much do you charge for an average website or app?",
    answer:
      "Once again, this depends. But my prices are affordable so you're good.",
  },
  {
    id: 3,
    question: "What's your payment plan?",
    answer:
      "Payment is divided into 3. An initial 30% upfront payment to get me started once all the details of the project is set, 40% once the project is completed, and 30% once the project is delivered.",
  },
  {
    id: 6,
    question: "What if the project doesn't turn out good?",
    answer:
      "You will be a part of the process from the start. You will know how the whole project is going until the end. And I will make sure to give you the best.",
  },
  {
    id: 7,
    question:
      "Do you provide ongoing maintenance and support after the project is completed?",
    answer:
      "Yes, I offer ongoing maintenance and support services after the project is completed. However, please note that ongoing maintenance services are typically provided at an additional cost. Whether it's fixing bugs, making updates, or providing technical assistance, I'm here to ensure that your project continues to run smoothly. Maintenance and support plans can be tailored to your specific needs and budget, and can be discussed further during the project planning phase.",
  },
  {
    id: 8,
    question:
      "What happens if there are additional features or changes requested during the project?",
    answer:
      "If additional features or changes are requested during the project, we can discuss the scope and impact of these changes together. Depending on the complexity and extent of the requested changes, it may affect the timeline and cost of the project. I will provide you with a revised proposal outlining the adjustments needed, including any changes to the timeline and pricing, before proceeding with implementing the requested features or changes. Open communication throughout the project ensures that we can address any modifications effectively while keeping the project on track.",
  },
];

export default data;
