import { FaAward } from "react-icons/fa";
import { TbBooks } from "react-icons/tb";

const data = [
  //{ id: 1, icon: <FaAward />, title: "Experience", desc: "2 Internships" },
  {
    id: 1,
    link: "#portfolio",
    icon: <TbBooks />,
    title: "Projects",
    desc: "3 Completed",
  },
];

export default data;
