import Image1 from "../../assets/project1.jpg";

const data = [
  {
    id: 1,
    category: "Front-End",
    image: Image1,
    title: "React Portfolio Website!",
    desc: "This portfolio website, built with React.js, showcases my skills and projects through a user-friendly interface. It features smooth navigation, responsiveness across devices, and detailed project descriptions. The site demonstrates my proficiency in building efficient, modern web applications using React and other modern JavaScript tools.",
    demo: "http://raphaelsaab.com",
    // github: "https://github.com/raphaelsaab16",
  },
];

export default data;
