import { AiOutlineInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";

import { AiFillGithub } from "react-icons/ai";

const data = [
  {
    id: 1,
    link: "https://www.instagram.com/raphaelsaab?igsh=MWtvNXE4eHByMG96OA%3D%3D&utm_source=qr",
    icon: <AiOutlineInstagram />,
  },
  { id: 2, link: "https://twitter.com/saab_raphael", icon: <FaXTwitter /> },
  { id: 4, link: "https://github.com/RaphaelSaab16", icon: <AiFillGithub /> },
];
export default data;
